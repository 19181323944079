import axios from "axios";
import config from "config";
import { logoutUser } from "stores/authStore";
import { setWindow } from "utils/winUtils";
import AuthServices from "./AuthServices";
import Utils from "./Utils";
const qs = require("querystring");

export interface ICMSQuery {
  page?: number;
  pageSize?: number;
  sort?: string;
  sortOrder?: string;
  includeCount?: boolean;
}

export interface ICMSQueryOptions {
  method?: string;
  route?: string;
  headers?: Record<string, string>;
  authToken?: string;
  query?: ICMSQuery;
  filter?: Record<string, string | number | boolean | null | any>;
  search?: string;
  include?: string[];
  exclude?: string[];
  noAuthToken?: boolean;
}

class HttpService {
  static createJqReq(
    url: string,
    headers: any,
    type: string,
    data = undefined
  ) {
    let objReq: any = {
      url,
      method: type,
      headers,
      data,
    };
    if (headers["Content-Type"] === "application/json" && type !== "GET") {
      objReq.data = JSON.stringify(data);
      objReq.processData = false;
    }

    if (
      headers["Content-Type"] === "application/x-www-form-urlencoded" &&
      type !== "GET"
    ) {
      objReq.data = qs.stringify(data);
    }

    return objReq;
  }

  static handleError(ex: any) {
    // let newEx = new Error(Utils.parseErrorString(ex));
    // //@ts-ignore
    // newEx.ex = ex;
    if (ex?.response?.data?.err?.name === "TokenExpiredError") {
      logoutUser();
    }
    throw ex;
  }

  static handleSuccess(data: any) {
    try {
      return data;
    } catch (ex) {
      return data;
    }
  }
  static async get(url: string, headers = {}) {
    try {
      let data = await axios(this.createJqReq(url, headers, "GET"));
      return this.handleSuccess(data);
    } catch (ex) {
      this.handleError(ex);
    }
  }

  static async post(url: string, data: any, headers = {}) {
    headers = { "Content-Type": "application/json", ...headers };
    try {
      let responseData = await axios(
        this.createJqReq(url, headers, "POST", data)
      );
      return this.handleSuccess(responseData);
    } catch (ex) {
      this.handleError(ex);
    }
  }

  static async put(url: string, data: any, headers = {}) {
    headers = { "Content-Type": "application/json", ...headers };
    try {
      let responseData = await axios(
        this.createJqReq(url, headers, "PUT", data)
      );
      return this.handleSuccess(responseData);
    } catch (ex) {
      this.handleError(ex);
    }
  }

  static async delete(url: string, data: any, headers = {}) {
    headers = { "Content-Type": "application/json", ...headers };
    try {
      let responseData = await axios(
        this.createJqReq(url, headers, "DELETE", data)
      );
      return this.handleSuccess(responseData);
    } catch (ex) {
      this.handleError(ex);
    }
  }

  // static async cmsList(collectionKey: string, options?: ICMSQueryOptions) {
  //   let result = await this.cmsQuery(
  //     collectionKey,
  //     {},
  //     { route: `/`, authToken: AuthServices.getToken(), ...options }
  //   );
  //   return result?.data;
  // }

  // static async cmsDetail(collectionKey: string, id: string) {
  //   let result = await this.cmsQuery(
  //     collectionKey,
  //     {},
  //     { route: `/${id}`, authToken: AuthServices.getToken() }
  //   );
  //   return result?.data;
  // }

  // static async cmsCreate(collectionKey: string, data: any) {
  //   let result = await this.cmsQuery(collectionKey, data, {
  //     route: `/`,
  //     method: "post",
  //     authToken: AuthServices.getToken(),
  //   });
  //   return result?.data;
  // }

  // static async cmsUpdate(collectionKey: string, id: string, data: any) {
  //   let result = await this.cmsQuery(collectionKey, data, {
  //     route: `/${id}`,
  //     method: "put",
  //     authToken: AuthServices.getToken(),
  //   });
  //   return result?.data;
  // }

  // static async cmsDelete(collectionKey: string, id: string) {
  //   let result = await this.cmsQuery(
  //     collectionKey,
  //     {},
  //     {
  //       route: `/${id}`,
  //       method: "delete",
  //       authToken: AuthServices.getToken(),
  //     }
  //   );
  //   return result?.data;
  // }

  // static async cmsQuery(
  //   collectionKey: string,
  //   data: any,
  //   options?: ICMSQueryOptions
  // ) {
  //   if (!options) options = {};
  //   options.headers = {
  //     "Content-Type": "application/json",
  //     ...options.headers,
  //   };
  //   if (!options.method) {
  //     options.method = "get";
  //   }
  //   if (!options.route) {
  //     options.route = "/";
  //   }
  //   if (options.authToken) {
  //     options.headers.Authorization = options.authToken;
  //   } else {
  //     if (options.noAuthToken !== false)
  //       options.headers.Authorization = AuthServices.getToken();
  //   }
  //   let queryValues = ``;
  //   if (options.query) {
  //     queryValues = Object.keys(options.query)
  //       .map((key) => `${key}=${((options?.query as any) || {})[key]}`)
  //       .join("&");
  //   }
  //   if (options.filter) {
  //     queryValues += `&filter=${JSON.stringify(options.filter)}`;
  //   }
  //   if (options.include) {
  //     queryValues += `&include=${options.include}`;
  //   }
  //   if (options.exclude) {
  //     queryValues += `&exclude=${options.exclude}`;
  //   }
  //   if (options.search) {
  //     queryValues += `&search=${options.search}`;
  //   }
  //   try {
  //     let url = `${config.ROOT_URL}/api/${collectionKey}${options.route}?${queryValues}`;
  //     let responseData = await axios(
  //       this.createJqReq(
  //         url,
  //         options.headers,
  //         options.method.toUpperCase(),
  //         data
  //       )
  //     );
  //     return this.handleSuccess(responseData?.data);
  //   } catch (ex) {
  //     this.handleError(ex);
  //   }
  // }

  static async getBase64FromURL(url: string) {
    return axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((response) =>
        Buffer.from(response.data, "binary").toString("base64")
      );
  }
}

setWindow("HttpService", HttpService);
export default HttpService;
