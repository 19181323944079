import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import RIcon from "common/misc/RIcon";
import { Store } from "pullstate";
import React, { ComponentProps, ReactNode } from "react";
import { createUUID } from "utils/uuidUtils";

const ConfirmStore = new Store<{ modals: IConfirmModalProps[] }>({
  modals: [],
});

export function ConfirmModalPanel() {
  let state = ConfirmStore.useState();
  return (
    <>
      {state.modals.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item.open && <ConfirmModal {...item} />}
          </React.Fragment>
        );
      })}
    </>
  );
}

export function showConfirmDialog(
  io: Omit<IConfirmModalProps, "open" | "onComplete" | "id"> & {
    dialogProps?: Partial<ComponentProps<typeof Dialog>>;
  }
) {
  return new Promise((resolve, reject) => {
    showDialog({
      ...io,
      isConfirm: true,
      onComplete: (confirm) => {
        resolve(confirm);
      },
    });
  });
}

export function showDialog(
  io: Omit<IConfirmModalProps, "open" | "onComplete" | "id"> & {
    dialogProps?: Partial<ComponentProps<typeof Dialog>>;
    onComplete?: (confirm: boolean) => void;
  }
) {
  let id = createUUID();
  let initialProps = {
    open: true,
    id,
    dialogProps: io.dialogProps as any,
    ...io,
    onComplete: (confirm?: boolean) => {
      ConfirmStore.update((s) => {
        s.modals = s.modals.filter((item) => item.id !== id);
      });
      if (io.onComplete) io.onComplete(confirm || false);
    },
  };
  ConfirmStore.update((s) => {
    s.modals.push(initialProps);
  });
  return {
    toggle: (open?: boolean) => {
      ConfirmStore.update((s) => {
        s.modals = s.modals.map((item) => {
          if (item.id === id) {
            item.open = open || !item.open;
          }
          return item;
        });
      });
    },
    update: (io: Partial<IConfirmModalProps>) => {
      ConfirmStore.update((s) => {
        s.modals = s.modals.map((item) => {
          if (item.id === id) {
            item = { ...item, ...io } as any;
          }
          return item;
        });
      });
    },
  };
}

interface IConfirmModalProps {
  id: string;
  title?: ReactNode;
  body?: ReactNode;
  dialogProps?: Partial<ComponentProps<typeof Dialog>>;
  open: boolean;
  isConfirm?: boolean;
  onComplete: (confirm: boolean) => void;
}
export function ConfirmModal(props: IConfirmModalProps) {
  // console.log(props);
  return (
    <>
      <Dialog
        open={props.open}
        maxWidth={"sm"}
        fullWidth
        onClose={() => {
          if (!props.isConfirm) props.onComplete(false);
        }}
        {...props.dialogProps}
      >
        {props.open && (
          <>
            {props.title && (
              <DialogTitle style={{ padding: 5 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {props.title}
                  <div style={{ flexGrow: 1 }}></div>
                  {!props.isConfirm && (
                    <IconButton
                      style={{ backgroundColor: "white" }}
                      onClick={() => {
                        props.onComplete(false);
                      }}
                    >
                      <RIcon name="close" />
                    </IconButton>
                  )}
                </div>
              </DialogTitle>
            )}
            {props.body && (
              <DialogContent style={{ paddingTop: 8 }}>
                {props.body}
              </DialogContent>
            )}
            <DialogActions>
              {props.isConfirm && (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      props.onComplete(true);
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={() => {
                      props.onComplete(false);
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
