import { LocalStorageUtils } from "hyper-utils";
import { Store } from "pullstate";
import AuthServices from "services/AuthServices";
import { IMasterClient, IMasterClientShort } from "types/masterClient";
import { IUser } from "types/user";
import { setWindow } from "utils/winUtils";

interface IAuthState {
  user: IUser | null;
  isUserDataFetched: boolean;
  selectedMasterClient: IMasterClientShort | null;
}

export const AuthStore = new Store<IAuthState>({
  user: null,
  isUserDataFetched: false,
  selectedMasterClient: LocalStorageUtils.lsGet("selectedMasterClient") || null,
});

AuthStore.listenToPatches((patches) => {
  let patch = patches.find((item) =>
    item.path.includes("selectedMasterClient")
  );
  if (patch) {
    LocalStorageUtils.lsSet("selectedMasterClient", patch.value);
  }
});

export const updateUserData = (userData: IUser | null) => {
  AuthStore.update((s) => {
    s.user = userData;
    s.isUserDataFetched = true;
    if (
      userData?.Access?.MasterClients &&
      userData?.Access?.MasterClients?.length > 0
    ) {
      if (s.selectedMasterClient) {
        let objMasterClient = userData?.Access?.MasterClients.find(
          (item) =>
            item.MasterClientID === s.selectedMasterClient?.MasterClientID
        );
        s.selectedMasterClient = objMasterClient || null;
      } else if (userData.Preferences?.DefaultMasterClientID) {
        let objMasterClient = userData?.Access?.MasterClients.find(
          (item) =>
            item.MasterClientID === userData.Preferences.DefaultMasterClientID
        );
        s.selectedMasterClient = objMasterClient || null;
      } else {
        s.selectedMasterClient = userData?.Access?.MasterClients[0];
      }
    } else {
      s.selectedMasterClient = null;
    }
  });
};

export function setSelectedMasterClient(
  selectedMasterClient: IMasterClientShort | null
) {
  AuthStore.update((s) => {
    s.selectedMasterClient = selectedMasterClient;
  });
}

export function logoutUser() {
  AuthServices.setToken("");
  AuthStore.update((s) => {
    s.user = null;
  });
}

export function useMasterClientID() {
  const { selectedMasterClient } = AuthStore.useState();

  return selectedMasterClient?.MasterClientID;
}
