import React, { useEffect } from "react";
import { useQuery } from "react-query";
import AuthServices from "services/AuthServices";
import { bSdk } from "services/BackendSDKService";
import { AuthStore, updateUserData } from "stores/authStore";
import { IUser } from "../types/user";

// interface IAuthContext {
//   uid: string;
//   isLoggedIn: () => boolean;
//   user: IUser;
// }

// export const AuthContext = React.createContext<IAuthContext | null>(null);

// export function AuthProvider({ children }: { children: any }) {
//   return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
// }

export function useAuth() {
  const { user, isUserDataFetched } = AuthStore.useState((state) => state);

  const authData = useQuery(
    "",
    async () => {
      if (AuthServices.getToken()) {
        try {
          let data = await bSdk.cmsQuery(
            "auth",
            {},
            {
              route: "/getUserInfo",
              authToken: AuthServices.getToken(),
              method: "get",
            }
          );
          updateUserData(data?.data);
          return data?.data;
        } catch (ex) {
          updateUserData(null);
        }
      } else {
        updateUserData(null);
      }
    },
    { enabled: false }
  );

  useEffect(() => {
    authData.refetch();
  }, []);

  return {
    user,
    isUserDataFetched,
  };
}
