import { useAuth } from "contexts/AuthContext";
import React, { useEffect } from "react";
import { IUser } from "types/user";
import { getWindow } from "utils/winUtils";
import config from "../../config";
import { useRouter } from "next/router";

function loadTawkScript(user?: IUser) {
  if (config.IS_DEVELOPMENT) return;
  console.log("loading tawk");
  getWindow().Tawk_API = getWindow().Tawk_API || {};
  if (user) {
    getWindow().Tawk_API.visitor = {
      name: `${user.FirstName} ${user.LastName}`,
      email: user.Email,
    };
  }

  getWindow().Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/64025d584247f20fefe3dae6/1gqkjp877";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    if (s0.parentNode) s0.parentNode.insertBefore(s1, s0);
  })();
}

export default function LoadScripts() {
  const { user } = useAuth();
  let router = useRouter();

  useEffect(() => {
    if (!router.asPath.includes("client/submit-revenue"))
      loadTawkScript(user || undefined);
  }, [user]);
  return null;
}
