import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import { CommonUtils, LocalStorageUtils } from "hyper-utils";
import { Component } from "react";
import ApiService from "services/ApiService";
import AuthServices from "services/AuthServices";
import Utils from "services/Utils";
import { updateUserData } from "stores/authStore";
import config from "../../config";
import { BroadcastChannel } from "broadcast-channel";

interface ILockScreenProps {
  shown?: boolean;
}

interface ILockScreenState {
  isScreenLocked: boolean;
  screenWillLock: boolean;
  password: string;
  isLoadingLogin: boolean;
  loginError?: any;
}

class LockScreen extends Component<ILockScreenProps, ILockScreenState> {
  constructor(props: any) {
    super(props);
  }
  state = {
    isScreenLocked: false,
    screenWillLock: false,
    password: "",
    isLoadingLogin: false,
    loginError: null,
  };
  channel = new BroadcastChannel("activity");
  lastActivityTimestamp = Date.now();
  checkLockInterval: any;
  resetAuthToken: any;
  lastTokenCheckTimestamp = Date.now();
  componentDidMount() {
    if (LocalStorageUtils.lsGet("isScreenLocked")) {
      this.logout();
      return;
    }

    this.channel.onmessage = (message) => {
      if (message === "activity") this.recordLastActivityTime();
      if (message?.token) {
        AuthServices.setToken(message.token);
      }
    };

    document.addEventListener("mousedown", this.onActivity);
    document.addEventListener("keydown", this.onActivity);

    document.addEventListener("LOCK_SCREEN", (event: any) => {
      this.lastActivityTimestamp = Date.now() - 60000 * 3600;
    });
    this.checkLockInterval = setInterval(this.checkForScreenLock, 1000);
    this.resetAuthToken = setInterval(this.resetToken, 1000 * 60 * 10); // 10 minutes
  }

  onActivity = () => {
    this.recordLastActivityTime();
    this.channel.postMessage("activity");
  };

  recordLastActivityTime = () => {
    if (!this.state.isScreenLocked) {
      this.lastActivityTimestamp = Date.now();
    }
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onActivity);
    document.removeEventListener("keydown", this.onActivity);

    clearInterval(this.checkLockInterval);
    clearInterval(this.resetAuthToken);
    this.channel.close();
  }

  resetToken = async () => {
    // 9 Minutes
    if (Math.abs(this.lastTokenCheckTimestamp - Date.now()) < 1000 * 60 * 9)
      return;
    let obj = await ApiService.getUserAndToken();
    if (obj.data.token) {
      AuthServices.setToken(obj.data.token);
      this.channel.postMessage({ token: obj.data.token });
    }
    this.lastTokenCheckTimestamp = Date.now();
  };

  checkForScreenLock = () => {
    let currentTimestamp = Date.now();
    let diffTimestampInMinutes =
      Math.abs(this.lastActivityTimestamp - currentTimestamp) / 1000 / 60;

    // return;
    // trigger screen will lock if difference is Grater them or equal to 4th of C_LOCK_SCREEN_AFTER_MINUTES
    if (diffTimestampInMinutes >= config.C_LOCK_SCREEN_AFTER_MINUTES) {
      // Lock screen not activity time reach to thrashold
      this.setState({ isScreenLocked: true });
      LocalStorageUtils.lsSet("isScreenLocked", true);
    } else if (
      diffTimestampInMinutes >=
      config.C_LOCK_SCREEN_AFTER_MINUTES -
        config.C_LOCK_SCREEN_AFTER_MINUTES / 4
    ) {
      this.setState({ screenWillLock: true });
    }

    // Logout if screen is locked for C_LOGOUT_IN_MINUTES_AFTER_SCREEN_LOCK minutes
    if (
      diffTimestampInMinutes >=
      config.C_LOCK_SCREEN_AFTER_MINUTES +
        config.C_LOGOUT_IN_MINUTES_AFTER_SCREEN_LOCK
    ) {
      // console.log("logout now");
      this.logout();
    }
  };

  showOverlay = () => {
    return this.state.screenWillLock || this.state.isScreenLocked;
  };

  login = async () => {
    try {
      this.setState({ isLoadingLogin: true });
      let result: any = await ApiService.login({
        Password: this.state.password,
        Username: AuthServices.getSystemUserName(),
      });
      let loginResponse = result.data;

      AuthServices.setToken(loginResponse.token);
      updateUserData(loginResponse.tokenData);

      this.lastActivityTimestamp = Date.now();
      this.setState({
        isScreenLocked: false,
        screenWillLock: false,
        password: "",
      });
    } catch (ex) {
      console.log(ex);
      // AuthServices.onLogout();
      this.setState({ loginError: ex });
    } finally {
      this.setState({ isLoadingLogin: false });
    }
  };

  logout = () => {
    LocalStorageUtils.lsSet("isScreenLocked", false);
    AuthServices.onLogout();
  };

  render() {
    if (!AuthServices.getSystemUserName()) return;
    let currentTimestamp = Date.now();
    let screenLockTimestamp =
      this.lastActivityTimestamp +
      config.C_LOGOUT_IN_MINUTES_AFTER_SCREEN_LOCK * 60 * 1000;
    let willLockIniSeconds = Math.round(
      Math.abs(screenLockTimestamp - currentTimestamp) / 1000
    );

    return (
      <div
        id="divLockScreen"
        style={{
          position: "fixed",
          top: "0px",
          left: "0px",
          height: "100%",
          width: "100%",
          background: "rgba(100,100,100,0.5)",
          display: this.showOverlay() ? "block" : "none",
          zIndex: 5000,
        }}
      >
        {this.state.screenWillLock && !this.state.isScreenLocked && (
          <LockScreenCenterContainer>
            <Card variant="elevation" elevation={5}>
              <CardContent style={{ padding: 30, textAlign: "center" }}>
                <Typography variant="h6">
                  System has identified that you are idle for a while now. You
                  are about to get locked out<br></br>
                  Screen will lock in <b>{willLockIniSeconds}</b> seconds
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  color="inherit"
                  variant="text"
                  onClick={() => {
                    this.lastActivityTimestamp = Date.now();
                    this.setState({
                      isScreenLocked: false,
                      screenWillLock: false,
                    });
                  }}
                >
                  Do not Lock
                </Button>
              </CardActions>
            </Card>
          </LockScreenCenterContainer>
        )}

        {this.state.isScreenLocked && (
          <LockScreenCenterContainer>
            <Card variant="elevation" elevation={5}>
              <CardHeader
                title={
                  <Typography
                    color="inherit"
                    align="center"
                    variant="subtitle1"
                  >
                    <b>Screen Lock</b>
                  </Typography>
                }
              />
              <CardContent style={{ padding: 30 }}>
                <Typography variant="subtitle2">
                  It seems like you are not actively using System at this time
                  and it is locked for security purposes. To Unlock, please
                  verify yourself by entering your APEX Password. You will be
                  logged out of Apex System if no action is taken
                </Typography>
                <br></br>
                <div style={{ textAlign: "center" }}>
                  <Typography variant="subtitle2" style={{ paddingBottom: 5 }}>
                    Username : <b>{AuthServices.getSystemUserName()}</b>
                  </Typography>
                  <TextField
                    type="password"
                    name="password"
                    label="Enter Password..."
                    value={this.state.password}
                    style={{ width: "50%" }}
                    fullWidth={false}
                    onChange={(e: any) => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                </div>
                {this.state.loginError && (
                  <ErrorPlaceHolder ex={this.state.loginError} />
                )}
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button onClick={this.login}>Authenticate Me</Button>
                <Button onClick={this.logout}>Logout</Button>
              </CardActions>
            </Card>
          </LockScreenCenterContainer>
        )}
      </div>
    );
  }
}

class LockScreenCenterContainer extends Component<any> {
  render() {
    return (
      <Grid container alignContent="space-around" style={{ height: "100%" }}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          {this.props.children}
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    );
  }
}

export default LockScreen;
