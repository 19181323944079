import { LocalStorageUtils } from "hyper-utils";
import { AuthStore, updateUserData } from "stores/authStore";
import { IUser } from "types/user";
import { createUUID } from "utils/uuidUtils";
import { getWindow } from "utils/winUtils";
import { clearAllCaches } from "./ReactQueryClient";
import { USER_ROLE } from "commonlib";

class AuthServices {
  static setToken(token: any) {
    LocalStorageUtils.lsSet("token", token);
  }

  static getToken() {
    return LocalStorageUtils.lsGet("token");
  }

  static onLogout() {
    this.setToken(null);
    updateUserData(null);
    // LocalStorageUtils.lsDelete("userData");
    // LocalStorageUtils.lsDelete("selectedMasterClient");
    // LocalStorageUtils.lsDelete("isScreenLocked");
    // LocalStorageUtils.lsDelete("token");
    localStorage.clear();
    clearAllCaches();
    if (typeof window !== "undefined") window.location.reload();
  }

  static setUserData(objUser: IUser) {
    LocalStorageUtils.lsSet("userData", objUser);
  }

  static getUserData() {
    if (!this.getToken()) return null;
    return LocalStorageUtils.lsGet("userData");
  }

  static removeToken() {
    LocalStorageUtils.lsDelete("token");
    LocalStorageUtils.lsDelete("userData");
  }

  static getSystemUserName() {
    return AuthStore.getRawState().user?.Username || "";
  }

  static getIsAdminUser() {
    return !!AuthStore.getRawState().user?.IsAdminUser;
  }

  static isSystemAdmin() {
    return (
      !!AuthStore.getRawState().user?.IsAdminUser &&
      AuthStore.getRawState().user?.Role === "SYSTEM_ADMIN"
    );
  }

  static is(role: keyof typeof USER_ROLE | "ADMIN") {
    let user = AuthStore.getRawState().user;
    if (!user) return false;
    if (user.IsAdminUser) {
      if (role === "ADMIN") return true;
      return user.Role === role;
    }
    if (!user.Access) return false;
    let accessKeys = Object.keys(user.Access);
    return accessKeys.some((key) => {
      return user && (user?.Access as any)[key].Role === role;
    });
  }

  static isOrganizationAdmin() {
    return (
      !!AuthStore.getRawState().user?.IsAdminUser &&
      AuthStore.getRawState().user?.Role === "ORGANIZATION_ADMIN"
    );
  }

  static isMasterAdminUser() {
    let masterClients = AuthStore.getRawState()?.user?.Access?.MasterClients;

    return Array.isArray(masterClients) && masterClients.length > 0;
  }

  static getDeviceID() {
    let deviceID = LocalStorageUtils.lsGet("deviceID");
    if (!deviceID && getWindow()) {
      deviceID = createUUID();
      localStorage.setItem("deviceID", deviceID);
    }
    return deviceID || "";
  }

  static isFeatureEnabled(feature: string) {
    let masterClient = AuthStore.getRawState().selectedMasterClient;
    if (!masterClient) return false;
    return masterClient.FeaturesEnabled?.includes(feature);
  }
}

export default AuthServices;
