import { BackendSDK } from "backendsdk";
import config from "config";
import { setWindow } from "utils/winUtils";
import AuthServices from "./AuthServices";

export const bSdk = new BackendSDK({
  rootURL: config.ROOT_URL,
  getAuthToken: AuthServices.getToken,
});

setWindow("bSdk", bSdk);
